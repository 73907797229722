var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c(
          "div",
          { staticClass: "d-flex align-center w-100" },
          [
            _c("span", { staticClass: "secondary--text font-weight-bold" }, [
              _vm._v("Tables"),
            ]),
            _c("v-spacer"),
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": false,
                    "nudge-width": 100,
                    "max-width": 650,
                    "offset-y": "",
                    left: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on: menu }) {
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on: tooltip }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                text: "",
                                                color: "secondary",
                                                dark: "",
                                              },
                                            },
                                            { ...tooltip, ...menu }
                                          ),
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center",
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [
                                                    _vm._v(
                                                      "mdi-book-open-variant"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "subtitle-2 font-weight-bold ml-1",
                                                  },
                                                  [_vm._v("connection guide")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v("Connection guide")])]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-card",
                    { staticStyle: { "overflow-y": "hidden" } },
                    [
                      _c("v-card-title", [
                        _c(
                          "span",
                          { staticClass: "secondary--text font-weight-bold" },
                          [_vm._v("Connection guide for external applications")]
                        ),
                      ]),
                      _vm.currentSpaceType !== _vm.spaceTypes.VENDOR_SPACE
                        ? _c("v-card-text", [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "subtitle-2 secondary--text" },
                                  [
                                    _vm._v(
                                      " If you want to connect to the database with a 3rd party application, please consult Nuvolos documentation "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "https://docs.nuvolos.cloud/features/database-integration/access-data-from-applications#accessing-data-tables-from-external-non-nuvolos-applications",
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v(" here ")]
                                    ),
                                    _vm._v(" . "),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "subtitle-2 secondary--text" },
                                  [
                                    _vm._v(
                                      "In any case, you'll need the following credentials to connect:"
                                    ),
                                  ]
                                ),
                                _vm._l(
                                  _vm.connectionGuideData,
                                  function (item, index) {
                                    return _c(
                                      "v-row",
                                      {
                                        key: index,
                                        attrs: {
                                          align: "center",
                                          "no-gutters": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "font-weight-bold text-uppercase secondary--text",
                                            attrs: { cols: "3" },
                                          },
                                          [_vm._v(_vm._s(item.item))]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "subtitle-2",
                                            attrs: { cols: "9" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center",
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.data) + " "
                                                ),
                                                _c("copy-to-clipboard", {
                                                  attrs: {
                                                    textToCopy: item.data,
                                                    buttonClass:
                                                      "caption font-weight-bold",
                                                    buttonColor: "primary",
                                                    buttonName:
                                                      "copy to clipboard",
                                                    iconButton: true,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _c(
                                  "span",
                                  { staticClass: "subtitle-2 secondary--text" },
                                  [
                                    _vm._v(
                                      " Once connected, execute the following SQL command to activate the schema corresponding to the currently selected state: "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between align-center wrap-flex",
                                    staticStyle: {
                                      "background-color":
                                        "rgba(23, 168, 193, 0.1)",
                                      width: "100%",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "mb-0 mt-0 ml-2 font-weight-bold caption",
                                        staticStyle: {
                                          color: "rgba(0, 0, 0, 0.54)",
                                        },
                                      },
                                      [_vm._v("SQL Editor")]
                                    ),
                                    _c("copy-to-clipboard", {
                                      attrs: {
                                        textToCopy: _vm.connectionQuery,
                                        buttonClass: "caption font-weight-bold",
                                        buttonColor: "primary",
                                        isTextButton: true,
                                        buttonName:
                                          "copy SQL command to clipboard",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("CodeHighlight", {
                                  attrs: {
                                    language: "sql",
                                    code: _vm.connectionQuery,
                                  },
                                }),
                              ],
                              2
                            ),
                          ])
                        : _vm.currentSpaceType === _vm.spaceTypes.VENDOR_SPACE
                        ? _c(
                            "v-card-text",
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: {
                                    type: "warning",
                                    prominent: "",
                                    text: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " You cannot access vendor datasets directly from 3rd party applications. To access data with an external application, stage the tables you want to work with and distribute them to a course/research space. "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      text: "",
                                      color: "secondary",
                                      href: "https://docs.nuvolos.cloud/features/database-integration/upload-data",
                                      target: "_blank",
                                    },
                                  },
                                  on
                                ),
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("menu_book"),
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "subtitle-2 font-weight-bold ml-1",
                                        },
                                        [_vm._v("import data")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Read the documentation on how to import and upload data to Nuvolos"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
          ],
          2
        ),
      ]),
      _c(
        "v-card-text",
        [
          _vm.orgTablesStatus === true &&
          _vm.tablesStatus === _vm.tableStatusOptions.ACTIVATED_TABLES
            ? [
                _c(
                  "v-tabs",
                  {
                    ref: "tabs",
                    staticClass: "mt-3 tabs-grey-border-bottom",
                    attrs: { color: "secondary" },
                    model: {
                      value: _vm.tab,
                      callback: function ($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab",
                    },
                  },
                  _vm._l(_vm.tabs, function (tab) {
                    return _c(
                      "v-tab",
                      { key: tab.tabName },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "mr-1", attrs: { small: "" } },
                          [_vm._v(_vm._s(tab.icon))]
                        ),
                        _vm._v(" " + _vm._s(tab.tabName) + " "),
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "v-tabs-items",
                  {
                    model: {
                      value: _vm.tab,
                      callback: function ($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab",
                    },
                  },
                  _vm._l(_vm.tabs, function (tabItem) {
                    return _c(
                      "v-tab-item",
                      { key: tabItem.tabName },
                      [_c(tabItem.component, { tag: "component" })],
                      1
                    )
                  }),
                  1
                ),
              ]
            : _vm.tablesStatus === _vm.tableStatusOptions.ACTIVATING_TABLES
            ? _c(
                "v-banner",
                { attrs: { "single-line": "" } },
                [
                  _c(
                    "v-avatar",
                    {
                      attrs: { slot: "icon", color: "white", size: "60" },
                      slot: "icon",
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { "x-large": "", icon: "info", color: "info" },
                        },
                        [_vm._v("info")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Tables are being activated"),
                    ]),
                    _c("span", [
                      _vm._v("Table activation can take a few minutes."),
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex align-center mt-2" },
                      [
                        _c("v-icon", { attrs: { color: "info", small: "" } }, [
                          _vm._v("mdi-arrow-right"),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "caption font-weight-bold info--text",
                          },
                          [
                            _vm._v(
                              " Upon completion of table activation, running applications need to be restarted in order to integrate the tables feature. "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm.tablesStatus === _vm.tableStatusOptions.INACTIVE_TABLES
            ? _c(
                "v-banner",
                {
                  attrs: { "single-line": "" },
                  scopedSlots: _vm._u(
                    [
                      _vm.isSpaceAdmin && _vm.tablesCanBeEnabled
                        ? {
                            key: "actions",
                            fn: function () {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      small: "",
                                      color: "info",
                                      outlined: "",
                                    },
                                    on: { click: _vm.activateTable },
                                  },
                                  [_vm._v("activate tables")]
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "v-avatar",
                    {
                      attrs: { slot: "icon", color: "white", size: "60" },
                      slot: "icon",
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { "x-large": "", icon: "info", color: "info" },
                        },
                        [_vm._v("info")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Tables not activated"),
                    ]),
                    _c("div", [
                      _vm._v(
                        " The tables feature hasn't been activated for this "
                      ),
                      _vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE
                        ? _c("span", [_vm._v("course")])
                        : _vm.currentSpaceType === _vm.spaceTypes.RESEARCH_SPACE
                        ? _c("span", [_vm._v("research project")])
                        : _vm.currentSpaceType === _vm.spaceTypes.VENDOR_SPACE
                        ? _c("span", [_vm._v("dataset")])
                        : _vm._e(),
                      _vm._v(" yet. "),
                      !_vm.isSpaceAdmin
                        ? _c("span", [
                            _vm._v(
                              "Contact a space administrator to request the activation of tables."
                            ),
                          ])
                        : !_vm.tablesCanBeEnabled
                        ? _c("span", [
                            _vm._v(
                              "Contact " +
                                _vm._s(
                                  _vm.whoEnablesFeature[
                                    _vm.tablesDisabledReason
                                  ]
                                ) +
                                " to enable it."
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ],
                1
              )
            : !_vm.tablesFetching &&
              _vm.orgTablesStatus === false &&
              !_vm.isTrialSpace
            ? _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-alert",
                        { attrs: { type: "info", prominent: "", text: "" } },
                        [
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Tables not activated"),
                            ]),
                            _vm._v(
                              " The current plan does not allow for the usage of Tables in Nuvolos. Please reach out to your organization administrator if you require this feature. "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : !_vm.tablesFetching
            ? _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-alert",
                        { attrs: { type: "info", prominent: "", text: "" } },
                        [
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _vm._v(
                              "Data on tables status is currently not avaiable."
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isTrialSpace
            ? _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-alert",
                        { attrs: { type: "info", prominent: "", text: "" } },
                        [
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("You discovered a premium feature!"),
                            ]),
                            _vm._v(" Subscribing to Nuvolos will unlock it. "),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }